export default {
  data() {
    return {
      shown: false,
    };
  },
  methods: {
    canUseCookies(privacyCookieName) {
      return (
        this.$cookies.get(privacyCookieName) ||
        !locations.privacy_cookie_country
      );
    },
    isModalAlreadyShown(cookieName) {
      return this.$cookies.get(cookieName);
    },
    show(positionInQueue = null) {
      this.$store.dispatch("ui/lightbox/show", {
        lightbox: this,
        positionInQueue: positionInQueue,
      });
    },
    hide() {
      this.$store.dispatch("ui/lightbox/hide", {});
    },
    setShown() {
      this.shown = true;
    },
    setHidden() {
      this.shown = false;
    },
  },
};
