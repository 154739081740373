var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pricing-detail" }, [
    _c("hr", { staticClass: "my-4" }),
    _vm._v(" "),
    _c("h5", [
      _c("strong", [
        _c("i", [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                fill: "none",
                viewBox: "0 0 24 24",
                "stroke-width": "1.5",
                stroke: "currentColor",
                width: "26",
                height: "26",
              },
            },
            [
              _c("path", {
                attrs: {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  d: "M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125",
                },
              }),
            ]
          ),
        ]),
        _vm._v("\n      " + _vm._s(_vm.i18nStrings.title) + "\n    "),
      ]),
    ]),
    _vm._v(" "),
    _vm.hourlyPrice
      ? _c("small", [_c("b", [_vm._v(_vm._s(_vm.i18nStrings.subtitle))])])
      : _vm._e(),
    _vm._v(" "),
    _vm.hourlyPrice
      ? _c("ul", { staticClass: "list-group price-hour" }, [
          _c(
            "li",
            {
              staticClass:
                "list-group-item d-flex justify-content-between align-items-center",
            },
            [
              _vm._v("\n      " + _vm._s(_vm.i18nStrings.from) + "\n      "),
              _c("span", { staticClass: "badge badge-light badge-pill" }, [
                _vm._v(_vm._s(_vm.currency) + " " + _vm._s(_vm.hourlyPrice)),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "mt-2" },
      _vm._l(_vm.section.tags, function (tag) {
        return _c("span", { key: tag.id, staticClass: "badge badge-pill" }, [
          tag.code === "credit_card"
            ? _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    "stroke-width": "1.5",
                    stroke: "currentColor",
                    width: "14",
                    height: "14",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      d: "M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z",
                    },
                  }),
                ]
              )
            : tag.code === "cash"
            ? _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    "stroke-width": "1.5",
                    stroke: "currentColor",
                    width: "14",
                    height: "14",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      d: "M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125",
                    },
                  }),
                  _vm._v("\\n' +\n      "),
                ]
              )
            : tag.code === "pix"
            ? _c("i", { staticClass: "icon-pix mr-2" })
            : _vm._e(),
          _vm._v("\n      " + _vm._s(tag.title) + "\n    "),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }