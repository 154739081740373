var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.templateHtml
    ? _c("div", { staticClass: "container px-0 related" }, [
        _c(
          "div",
          { staticClass: "col mt-5 mb-5" },
          [_c("RenderHtml", { attrs: { html: _vm.templateHtml } })],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }