var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "carousel slide",
      attrs: { id: this.carouselId, "data-ride": "carousel" },
    },
    [
      _c(
        "div",
        { staticClass: "carousel-inner" },
        [
          _vm._l(_vm.strings, function (string, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "carousel-item ",
                class: { active: index === 0 },
              },
              [_vm._v("\n            " + _vm._s(string) + "\n        ")]
            )
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "carousel-control-prev",
              attrs: {
                "data-href": this.carouselId,
                role: "button",
                "data-slide": "prev",
              },
            },
            [
              _c("span", {
                staticClass: "carousel-control-prev-icon",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "sr-only" }, [_vm._v("Previous")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "carousel-control-next",
              attrs: {
                "data-href": this.carouselId,
                role: "button",
                "data-slide": "next",
              },
            },
            [
              _c("span", {
                staticClass: "carousel-control-next-icon",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "sr-only" }, [_vm._v("Next")]),
            ]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }