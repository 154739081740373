export const loader = {
    namespaced: true,
    state: {
        shown: false
    },
    mutations: {
        show(state) {
            state.shown = true
        },
        hide(state) {
            state.shown = false
        },
        toggle(state) {
            state.shown = !state.shown
        }
    },
    actions: {
        show({commit, state}) {
            if (!state.shown) {
                commit("show")
            }
        },
        hide({commit, state}) {
            if (state.shown) {
                commit("hide")
            }
        },
        toggle(context) {
            context.commit("toggle")
        }
    },
    getters: {
        shown: state => {
            return state.shown
        }
    }
}
