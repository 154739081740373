var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.templateHtml
    ? _c("div", { staticClass: "container px-0 related" }, [
        _c(
          "div",
          { staticClass: "col mt-5 mb-5" },
          [
            _c("h4", {
              staticClass: "text-center",
              domProps: { textContent: _vm._s(_vm.title) },
            }),
            _vm._v(" "),
            _c("RenderHtml", { attrs: { html: this.templateHtml } }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }