<template>
  <div class="tags-sections-detail" v-if="!isFree">
    <tags-list-detail
        v-for="section in hierarchy.sections"
        v-bind:key="section.code"
        v-if="!isPricingSection(section)"
        :section="section"
    ></tags-list-detail>

    <pricing-detail
        v-if="showPricingSection"
        :key="pricingSection.code"
        :section="pricingSection"
        :hourly-price="hourlyPrice"
        :currency="currency"
    ></pricing-detail>
  </div>
</template>

<script>
import TagsListDetail from "./tags-list-detail.vue";
import PricingDetail from "./pricing-detail.vue";

export default {
  name: 'TagsSectionsDetail',
  components: {
    PricingDetail,
    TagsListDetail
  },
  props: {
    hierarchy: Object,
    hourlyPrice: Number,
    currency: String,
    isFree: Boolean
  },
  computed: {
    pricingSection() {
      for (let section of this.hierarchy.sections) {
        if (this.isPricingSection(section)) {
          return section;
        }
      }
      return {};
    },
    showPricingSection() {
      if (this.hourlyPrice) {
        return this.hourlyPrice;
      } else {
        if (this.pricingSection && this.pricingSection.code) {
          return this.pricingSection.code;
        }
      }
    }
  },
  methods: {
    isPricingSection(section) {
      return section.code === "payment_methods";
    },
  },
}
</script>
