import skokka from './skokka';
import axios from  'axios';

const forceBuild = '20220408H12:00';

const SkokkaPlugin = (function() {

    function install(vue){
        vue.prototype.$skokka=skokka;

        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        vue.prototype.$http=axios;
            // add to window
        if(typeof window!=="undefined"){
            window.$skokka = skokka;
            window.$http = axios;
        }
    }

    return {
        install
    };

})();

export default SkokkaPlugin;