import {
    filterWorkNames
} from "../../../../utils";

export const lightboxManager = {
    namespaced: true,
    state: {
        registeredModels: [],
        queue: [],
        current: null,
        didUserInteractWithPage : false,
    },
    getters: {
        lightboxIsShown: (state) => (lightboxName) => {
            return lightboxName ? state.current === lightboxName : false;
        }
    },
    mutations: {
        REGISTER(state, name) {
            state.registeredModels.push(name);
        },
        HIDE(state) {
            state.queue = state.queue.slice(1);
            state.current = null;
        },
        SHOW(state) {
            state.current = state.queue[0];
        },
        ADD_TO_QUEUE(state, names) {
            state.queue = [...names, ...state.queue];
        },
        REMOVE_FROM_QUEUE(state, index) {
            state.queue.splice(index, 1);
        },
        SET_DID_USER_ITERACT_WITH_PAGE(state, nextState) {
            state.didUserInteractWithPage = nextState;
        }
    },
    actions: {
        register({ state, commit }, name) {
            if (state.registeredModels.indexOf(name) == -1) {
                commit('REGISTER', name);
            }
            else {
                console.warn(`lightbox with name:${name} already registered`);
            }
        },
        show({ state, commit }, names) {
            if (names) {
                const workNames = filterWorkNames({ names, state });

                if(workNames.length) {
                    commit('ADD_TO_QUEUE', workNames);
                }
            }

            if (!state.didUserInteractWithPage) {
                return;
            }

            if (!state.current && state.queue.length) {
                commit('SHOW');
            }
        },
        hide({ commit }) {
            commit("HIDE");
            commit("SHOW");
        },
        userInteractionDetected({ dispatch, commit }){
            commit("SET_DID_USER_ITERACT_WITH_PAGE", true);
            dispatch('show');
        },
        addToQueue({ state, commit }, names) {
            if (names) {
                const workNames = filterWorkNames({ names, state });

                if(workNames.length) {
                    commit('ADD_TO_QUEUE', workNames);
                }
            }
        },
        removeFromQueue({ state, commit }, name) {
            const index = state.queue.indexOf(name);
            if (index !== -1) {
                commit('REMOVE_FROM_QUEUE', index);
            }
        },
        removeAndHide({ state, dispatch, commit }, lightboxName) {
            dispatch('removeFromQueue', lightboxName);

            if (state.current === lightboxName) {
              commit("HIDE");
            }
        }
    }
}
