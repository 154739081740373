var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "lightbox",
      staticClass: "modal",
      class: { show: _vm.shown },
      style: _vm.shown ? "display: block;" : "display: none;",
      attrs: {
        id: "lightbox-block",
        tabindex: "-1",
        "data-backdrop": "static",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered modal-lg",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("p", {
                staticClass: "modal-title text-uppercase",
                domProps: { innerHTML: _vm._s(this.translations.warning) },
              }),
            ]),
            _vm._v(" "),
            _vm.postUpdateHref || _vm.isUpdatePage
              ? _c("div", [
                  _c("div", { staticClass: "modal-body" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column bd-highlight mb-3" },
                      [
                        _c("div", { staticClass: "p-2 bd-highlight" }, [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(this.translations.similar_ad),
                            },
                          }),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-footer justify-content-center" },
                    [
                      _c("div", [
                        _c("button", {
                          staticClass:
                            "btn btn-primary waves-effect waves-light",
                          attrs: { type: "button" },
                          domProps: {
                            innerHTML: _vm._s(this.translations.edit),
                          },
                          on: { click: _vm.onEdit },
                        }),
                      ]),
                    ]
                  ),
                ])
              : _c("div", [
                  _c("div", { staticClass: "modal-body" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column bd-highlight mb-3" },
                      [
                        _c("div", {
                          staticClass: "p-2 bd-highlight",
                          domProps: {
                            innerHTML: _vm._s(this.translations.modal_body),
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-footer justify-content-center" },
                    [
                      _c("button", {
                        staticClass: "btn btn-primary waves-effect waves-light",
                        attrs: { type: "button" },
                        domProps: {
                          innerHTML: _vm._s(this.translations.close),
                        },
                        on: { click: _vm.onClose },
                      }),
                    ]
                  ),
                ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }