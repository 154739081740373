<template>
  <div class="container listing sticky-top bg-white p-2">
    <section class="listing-tags">
      <div class="stikysearch">
        <a data-toggle="modal" data-target="#staticBackdrop" @click="activateSearchDialog()">
          <div class="d-flex justify-content-between align-items-center py-2 px-3">
            <span :class="{ nogeo: !locationText }">
              <span class="icon-map-pin mr-2"></span>
              {{ locationText ? locationText : i18nStrings.placeholder }}
            </span>
            <i class="text-clipped d-flex justify-content-end">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" width="18" height="18">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
              </svg>
            </i>
          </div>
        </a>
      </div>
      <div class="form-row inlinetag px-2 pt-2 pb-0 ">
        <div class="taglist filtersearch">
          <!-- tag badge -->
          <label v-for="tag in tags" :key="tag.code" @click="removeTag(tag)">
            <input type="checkbox" class="Checkbox">
            <div class="listingfilters">
              <span class="listingfiltersClose icon icon-close"></span>
              <span>{{ tag.title }}</span>
            </div>
          </label>
          <!-- end tag badge -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import Translations from "./insert-me-to-translate.vue";

export default {
  name: 'AdvancedSearch',
  props: {
    translationsTags: Object,
    initRegion: String,
    initCity: String,
    initDistrict: String
  },
  data() {
    return {
      region: {},
      city: {},
      district: {},
      tags: [],
      i18nStrings: {
        "placeholder": gettext("Search by city, Category..."),
      }
    }
  },
  computed: {
    locationText() {
      let text = "";
      if (this.region) {
        text += this.region.name;
      }
      if (this.city) {
        if (this.region) text += " - ";
        text += this.city.name;
      }
      if (this.district) {
        text += " - " + this.district.name;
      }
      return text;
    },
    allTags() {
      const tags = [];
      for (const tagCode in this.translationsTags) {
        tags.push(this.translationsTags[tagCode]);
      }
      return tags;
    }
  },
  mounted() {
    this.hideBorderBottom();
    this.hideSearchIcon();
    this.region = this.getRegion();
    this.city = this.getCity();
    this.district = this.getDistrict();
    this.tags = this.getTagsFromUrl();
  },
  methods: {
    hideBorderBottom() {
      // this function is a workaround to hide the border-bottom of header
      const element = document.getElementsByClassName("cusotm-nav-container")[0];
      if (element) {
        element.style.borderColor = "white";
      }
    },
    hideSearchIcon() {
      document.getElementById("search-modal-icon").style.display = "none";
    },
    activateSearchDialog: function () {
      this.$root.activateSearchDialog()
    },
    getRegion() {
      return locations.getRegionByPk(this.initRegion);
    },
    getCity() {
      return locations.getCityByPk(this.initCity);
    },
    getDistrict() {
      return locations.getDistrictByPk(this.initDistrict);
    },
    getTagsFromUrl() {
      // Get the query string from the current URL
      const queryString = window.location.search;
      const searchParams = new URLSearchParams(queryString);
      const tags = searchParams.get('tags');
      if (tags) {
        let sanitizedTags = tags.split(',');
        sanitizedTags = this.sanitizeTags(sanitizedTags);
        return sanitizedTags;
      }
      return [];
    },
    sanitizeTags(tags) {
      const sanitized = [];
      for (const tag of tags) {
        const foundTag = this.allTags.find(x => {
          if (x) {
            return x.code === tag;
          }
          return false;
        });
        if (foundTag) {
          sanitized.push(foundTag);
        }
      }
      return [...new Set(sanitized)];
    },
    removeTag(tag) {
      this.tags = this.tags.filter(t => t !== tag);
      const urlParams = new URLSearchParams(window.location.search);
      const tagsString = this.tags.map(x => {
        if (x) {
          return x.code;
        }
      }).join(",");
      if (tagsString) {
        urlParams.set("tags", tagsString);
      } else {
        urlParams.delete("tags");
      }
      urlParams.delete("p"); // delete the pagination param for starting to page 1
      const currentUrl = location.protocol + '//' + location.host + location.pathname;
      let newUrl = currentUrl;
      if (urlParams.toString()) {
        newUrl += '?' + urlParams.toString();
      }
      location.href = newUrl;
    },
  }
}
</script>
