/*
    This module is deprecated.
    Consumend only by lightbox.vue component
*/ 
export const lightbox = {
    namespaced: true,
    state: {
        shown: false,
        queue:[],
        genericLightbox: null
    },
    mutations: {
        putOnQueue(state, lightbox) {
            state.queue.push(lightbox)
        },
        setGenericLightbox(state, lightbox){
            state.genericLightbox=lightbox
        },
        show(state, lightbox) {
            lightbox.setShown()
            state.shown=lightbox
            document.body.classList.add('modal-open')
        },
        hide(state) {
            state.shown = false
            document.body.classList.remove('modal-open')
        },
        toggle(state) {
            state.shown = !state.shown
        },
        putOnQueueWithPosition(state,{ lightbox, positionInQueue}){
            state.queue.splice(positionInQueue, 0, lightbox)
        }
    },
    actions: {
        putOnQueue(context, lightbox) {
            context.commit("putOnQueue", lightbox)
        },
        setGenericLightbox(context, {lightbox}) {
            context.commit("setGenericLightbox", lightbox)
        },
        show(context, {title, body, html, lightbox, positionInQueue}={}, ) {
            if (!lightbox) {
                lightbox=context.state.genericLightbox
                lightbox.setTitle(title)
                lightbox.setBody(body)
                lightbox.setHtml(html)
            }
            if(lightbox) {
                if (!context.state.shown && !positionInQueue) {
                    context.commit("show", lightbox)
                } else {
                    context.commit("putOnQueueWithPosition", {lightbox:lightbox, positionInQueue:positionInQueue})
                }
            }
        },
        hide({commit, state}) {
            if (state.shown) {
                state.shown.setHidden()
                commit("hide")
                if(state.queue.length>0){
                    commit("show",state.queue.shift())
                }
            }
        },
        toggle(context) {
            context.commit("toggle")
        }
    }
}
