<template>
    <div
        class="modal fade"
        :class="{ show: shown }"
        id="basicExampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        style="display: block; padding-right: 15px"
        :style="shown ? 'display: block; padding-right: 15px' : 'display: none'"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        {{ title }}
                    </h5>
                    <button
                        @click="close"
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        :aria-label="closeText"
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body" ref="body">
                    {{ body }}
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        @click="close"
                        data-dismiss="modal"
                    >
                        {{ closeText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Translations from "./insert-me-to-translate.vue";

import lightboxMixin from "../mixins/lightbox-mixin"

export default {
    mixins: [lightboxMixin],
    data: function () {
        return {
            closeText: gettext("close"),
            title: null,
            body: null,
            html:null
        };
    },
    methods: {
        close: function () {
            this.hide();
        },
        setBody:function(body){
            this.body=body;
        },
        setTitle:function(title){
            this.title=title;
        },
        setHtml:function(html){
            this.html=html;
        }
    },
    mounted(){
        this.$store.dispatch("ui/lightbox/setGenericLightbox",{lightbox:this});
    },
    updated() {
        if (this.html) {
            this.$refs.body.appendChild(this.html);
        } else {
            while (this.$refs.body.lastElementChild) {
                this.$refs.body.lastElementChild
                this.$refs.body.removeChild(this.$refs.body.lastElementChild);
            }
        }
    },
};
</script>

<style scoped>
/deep/ .modal-body > * {
    display: block;
    margin: auto;
}
</style>