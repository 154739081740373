var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal fade show",
        class: { show: _vm.shown },
        style: _vm.shown ? "display: block; z-index: 1030;" : "display: none;",
        attrs: {
          id: "cookieBannerV2",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "cookieBannerV2",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content text-center" }, [
              _c("div", { staticClass: "modal-body medium mt-4" }, [
                _c("p", [_c("b", [_vm._v(_vm._s(_vm.t.aboutCookies))])]),
                _vm._v(" "),
                _c("p", { staticClass: "medium" }, [
                  _vm._v(_vm._s(_vm.t.aboutCookiesDescription)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer d-block" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.acceptAll },
                  },
                  [_vm._v(_vm._s(_vm.t.acceptAll))]
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-link skokka-text",
                    attrs: { type: "button" },
                    on: { click: _vm.manageCookies },
                  },
                  [_vm._v(_vm._s(_vm.t.manageCookies))]
                ),
                _vm._v(" "),
                _c("p", {
                  staticClass: "small",
                  domProps: { innerHTML: _vm._s(_vm.t.moreInfos) },
                }),
              ]),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        class: { show: _vm.showCookieManager },
        style: _vm.showCookieManager ? "display: block;" : "display: none;",
        attrs: {
          id: "cookieBannerV2ManageCookie",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "cookieBannerV2ManageCookie",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content text-center" }, [
              _c("div", { staticClass: "modal-body mt-4" }, [
                _c("small", [
                  _c("p", [_vm._v(_vm._s(_vm.t.personalizeCookies))]),
                  _vm._v(" "),
                  _c("p", { domProps: { innerHTML: _vm._s(_vm.t.moreInfos) } }),
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "list-group text-left txt_medium" }, [
                  _c("li", { staticClass: "list-group-item" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col pl-2 pr-0" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              "data-toggle": "collapse",
                              href: "#collapseStrictlyNecessary",
                              role: "button",
                              "aria-expanded": "false",
                              "aria-controls": "collapseStrictlyNecessary",
                            },
                          },
                          [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(_vm.t.strictlyNecessary) +
                                " \n                                        "
                            ),
                            _c("i", { staticClass: "arrow-r" }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "small text-right pr-2" }, [
                        _c("b", [_vm._v(_vm._s(_vm.t.alwaysActive))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "small mb-0 pt-3 collapse",
                        attrs: { id: "collapseStrictlyNecessary" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.t.strictlyNecessaryDescription) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass:
                        "list-group-item justify-content-between align-items-center",
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col pl-2 pr-0 pt-2" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#collapseAnalytical",
                                role: "button",
                                "aria-expanded": "false",
                                "aria-controls": "collapseAnalytical",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(_vm.t.analyticalPerformance) +
                                  " "
                              ),
                              _c("i", { staticClass: "arrow-r" }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "switch switch-left-right float-right",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.hasPerformanceCookie,
                                  expression: "hasPerformanceCookie",
                                },
                              ],
                              staticClass: "switch-input",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.hasPerformanceCookie)
                                  ? _vm._i(_vm.hasPerformanceCookie, null) > -1
                                  : _vm.hasPerformanceCookie,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.hasPerformanceCookie,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.hasPerformanceCookie = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.hasPerformanceCookie = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.hasPerformanceCookie = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "switch-label" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "switch-handle" }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "small mb-0 p-3 collapse",
                            attrs: { id: "collapseAnalytical" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.t.analyticalPerformanceDescription)
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass:
                        "list-group-item justify-content-between align-items-center",
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col pl-2 pr-0 pt-2" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#collapseAdvertising",
                                role: "button",
                                "aria-expanded": "false",
                                "aria-controls": "collapseAdvertising",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(_vm.t.advertising) +
                                  " "
                              ),
                              _c("i", { staticClass: "arrow-r" }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "switch switch-left-right float-right",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.hasAdvertisingCookie,
                                  expression: "hasAdvertisingCookie",
                                },
                              ],
                              staticClass: "switch-input",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.hasAdvertisingCookie)
                                  ? _vm._i(_vm.hasAdvertisingCookie, null) > -1
                                  : _vm.hasAdvertisingCookie,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.hasAdvertisingCookie,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.hasAdvertisingCookie = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.hasAdvertisingCookie = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.hasAdvertisingCookie = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "switch-label" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "switch-handle" }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "collapse",
                            attrs: { id: "collapseAdvertising" },
                          },
                          [
                            _c("p", { staticClass: "small mb-0 p-3" }, [
                              _vm._v(
                                " " + _vm._s(_vm.t.advertisingDescription)
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer borderless d-block" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.acceptAll },
                  },
                  [_vm._v(_vm._s(_vm.t.acceptAll))]
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.acceptMyPreferences },
                  },
                  [_vm._v(_vm._s(_vm.t.acceptMyPreferences))]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "small pt-3" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.rejectAllNonNecessary.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.t.rejectAllNonNecesary))]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }