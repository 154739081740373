
export const coupons = {
    namespaced: true,
    state: {
        page: null,
        addCoupon:null,
        couponsList: null,
        previewCoupon: null,
        lastAddonsCoupon: null,
        justAddedCoupon: null
    },
    mutations: {
        init(state, page) {
            state.page = page
        },
        setCouponsList(state, couponsList) {
            state.couponsList=couponsList
        },
        setPreviewCoupon(state, coupon) {
            state.previewCoupon=coupon
        },
        setJustAddedCoupon(state, coupon) {
            state.justAddedCoupon=coupon
        },
        setLastAddonsCoupon(state, coupon) {
            state.lastAddonsCoupon=coupon
        },
        setAddCoupon(state, addCoupon) {
            state.addCoupon=addCoupon
        },
    },
    actions: {
        init(context, order) {
            context.commit('init', order)
        },
        addCoupon(context, coupon) {
            context.commit('addCoupon', coupon)
        },
        setCouponsList(context, list){
            context.commit('setCouponsList', list)
        },
        setPreviewCoupon(context, coupon){
            context.commit('setPreviewCoupon', coupon)
        },
        setJustAddedCoupon(context, coupon){
            context.commit('setJustAddedCoupon', coupon)
        },
        setLastAddonsCoupon(context, coupon){
            context.commit('setLastAddonsCoupon', coupon)
        },
        setAddCoupon(context, addCoupon){
            context.commit('setAddCoupon', addCoupon)
        },
    },
    getters: {
        couponsList: state => {
            return state.couponsList
        },
        page: state => {
            return state.page
        },
        previewCoupon: state => {
            return state.previewCoupon
        },
        hasPreviewCoupon: state => {
          return (state.previewCoupon && state.previewCoupon.loadedCoupon)
        },
        previewCouponId: state => {
          return state.previewCoupon.coupon.id
        },
        justAddedCoupon: state => {
            return state.justAddedCoupon
        },
        lastAddonsCoupon: state => {
            return state.lastAddonsCoupon
        },
        addCoupon: state =>{
            return state.addCoupon
        },
    },
}
