<template>
    <div v-if="posts.length > 0" v-cloak>
        <div class="skokka-stories-list">
            <div id="stories-right-button" v-show="visibleScrollButtons.left" :style="{visibility: visibleScrollButtons.right ? 'visible' : 'hidden'}" @click.stop.prevent="scrollRight"><a href="#"></a></div>
            <div id="outer" ref="storiesOuterDiv">
                <div id="inner">
                    <div v-for="(post, index) in posts"
                        :key="`thumb-${index}`"
                        class="stories_thumb">
                        <a href="#" @click.stop.prevent="openModal(index)">
                            <picture class="stories_thumb-media">
                                <img v-if="index===0" :src="thumbMediaSrc(post)" :alt="post.title" draggable="false" />
                                <img v-else :src="thumbMediaSrc(post)" :alt="post.title" draggable="false" loading="lazy" />
                            </picture>
                            <span class="badge badge-supertop mt-3"><i class="icon-supertop mr-1"></i></span>
                            <div class="user-username">
                                {{ post.title }}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div id="stories-left-button" :style="{visibility: visibleScrollButtons.left && !scrollCompleted ? 'visible' : 'hidden'}" @click.stop.prevent="scrollLeft"><a href="#"></a></div>
        </div>

        <div ref="storiesModal" v-if="modalIndex !== null" class="modalstories modal fade" interval="0" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="dismiss">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content bg-dark">
                    <div class="modal-header border-0">
                        <h5 class="modal-title text-title cursor-pointer onlydk" @click="goToDetail">
                            {{ posts[modalIndex].title }}
                            <div class="tagcard">
                                <span class="badge-pill">
                                    <b>
                                        {{ posts[modalIndex].age }} {{ i18nStrings.years }}
                                    </b>
                                </span>
                                <span class="badge-pill">
                                    <b>
                                        {{ posts[modalIndex].categoryName }}
                                    </b>
                                </span>
                                <span translate="no" class="badge-pill">
                                    <i class="icon icon-map-pin mr-1"></i>
                                        <b>
                                            {{ posts[modalIndex].postCityName }}
                                        </b>{{ posts[modalIndex].postListingPlace ? ` / ${posts[modalIndex].postListingPlace}` : "" }}
                                </span>
                            </div>

                        </h5>
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div ref="storiesModalCarousel" id="storiesModalCarousel" class="carousel slide" data-interval="0">
                            <ol v-if="posts[modalIndex].pictures.length > 1" class="carousel-indicators stories">
                                <li v-for="(picture, index) in posts[modalIndex].pictures"
                                    :key="`indicator-${modalIndex}-${picture}`"
                                    :class="{active: activeModalImageIndex === index}"
                                    :style="`background-size: ${activeModalImageIndex === index ? currentAnimation.timePerc : 0}% 100%`"
                                    @click="slideCarouselTo(index)">
                                </li>
                            </ol>
                            <div ref="storiesModalInner" class="carousel-inner modalstories" role="listbox">
                                <div v-for="(picture, index) in posts[modalIndex].pictures"
                                    :key="`img-${modalIndex}-${picture}`"
                                    :ref="`carouselPicture${index}`"
                                    :class="`carousel-item ${activeModalImageIndex === index ? 'active' : 'modalstories'}`"
                                    :style="`background-image: url('${picture}')`">
                                </div>
                            </div>
                            <a v-if="posts[modalIndex].pictures.length > 1" class="carousel-control-prev" role="button" @click="goToPreviousImage">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">{{ i18nStrings.previous }}</span>
                            </a>
                            <a v-if="posts[modalIndex].pictures.length > 1" class="carousel-control-next" role="button" @click="goToNextImage">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">{{ i18nStrings.next }}</span>
                            </a>
                        </div>
                    </div>

                    <div class="modal-footer justify-content-center border-0 w-100">
                        <h5 class="modal-title text-title cursor-pointer onlymobile w-100" @click="goToDetail">
                            {{ posts[modalIndex].title }}
                        </h5>
                        <div class="tagcard onlymobile">
                            <span class="badge-pill">
                                <b>
                                    {{ posts[modalIndex].age }} {{ i18nStrings.years }}
                                </b>
                            </span>
                            <span class="badge-pill">
                                <b>
                                    {{ posts[modalIndex].categoryName }}
                                </b>
                            </span>
                            <span translate="no" class="badge-pill">
                                <i class="icon icon-map-pin mr-1"></i>
                                    <b>
                                        {{ posts[modalIndex].postCityName }}
                                    </b>{{ posts[modalIndex].postListingPlace ? ` / ${posts[modalIndex].postListingPlace}` : "" }}
                            </span>
                        </div>
                        <button type="button" class="btn btn-primary" @click="goToDetail">{{ i18nStrings.goToDetailText }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Translations from "./insert-me-to-translate.vue";

import Url from "domurl";
import debounce from 'lodash/debounce';
import has from 'lodash/has';
import isString from 'lodash/isString';

// see: https://github.com/tserkov/vue-plugin-load-script/blob/master/index.js
const loadTouchSwipe = function() {
    return new Promise(function(resolve, reject) {
        const src = "https://cdnjs.cloudflare.com/ajax/libs/jquery.touchswipe/1.6.19/jquery.touchSwipe.min.js";
        let shouldAppend = false;
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
            el = document.createElement('script');
            el.type = 'text/javascript';
            el.async = true;
            el.src = src;
            shouldAppend = true;
        } else if (el.hasAttribute('data-loaded')) {
            resolve(el);
            return;
        }

        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', function loadScriptHandler() {
            el.setAttribute('data-loaded', true);
            resolve(el);
        });

        if (shouldAppend) document.head.appendChild(el);
    });
};

const getCurrentAnimationDefaults = function(){
    return {
        timePerc: 0,
        frameRequestId: -1,
        startTime: null,
        pauseStartTime: null,
        totalPauseTime: 0,
        paused: false,
    };
};

export default {

    props: {
        posts: {
            type: Array,
            default: () => {
                return [];
            },
            validator: (value) => {
                for(let i = 0; i < value.length; i++) {
                    const el = value[i];
                    if(!el.detail_href || !el.pictures || !el.title || !el.code) {
                        return false;
                    }
                }
                return true;
            },
        },
        currentPage: {
            type: Number,
            required: false,
            default: null,
            validator: (value) => {
                return value >= 1;
            },
        },
        imageSrcVersion: {
            type: Object,
            required: true,
            validator:  (obj) => {
                return has(obj, 'name') && has(obj, 'value') && isString(obj.name) && isString(obj.value);
            }
        },
        postCityName: {
            type: String
        },
        postListingPlace: {
            type: String
        }
    },

    data: function() {
        return {
            storyTimeMs: 4000,

            modalIndex: null,
            activeModalImageIndex: 0,

            currentAnimation: getCurrentAnimationDefaults(),

            debouncedUpdateUI: debounce(this.updateUI, 250),

            visibleScrollButtons:{
                right: false,
                left: false,
            },

            scrollCompleted: false,

            i18nStrings: {
                goToDetailText: gettext("Find out more"),
                previous: gettext("Previous"),
                next: gettext("Next"),
                years: gettext("years")
            }
        };
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.debouncedUpdateUI);
    },

    mounted() {
        window.addEventListener("resize", this.debouncedUpdateUI);
        this.$nextTick().then(() => {
            this.updateUI();
        });
    },

    methods: {
        scrollLeft(){
            const outer = $(this.$refs.storiesOuterDiv);
            this.visibleScrollButtons.right = true;
            const leftPos = outer.scrollLeft();
            outer.animate({
                scrollLeft: leftPos + 200
            }, 800, () => {
                this.scrollCompleted =  leftPos + outer.get(0).clientWidth + 200 >= outer.get(0).scrollWidth;
            });
        },

        scrollRight() {
            const outer = $(this.$refs.storiesOuterDiv);
            const leftPos = outer.scrollLeft();
            outer.animate({
                scrollLeft: leftPos - 200
            }, 800, (() => {
                this.visibleScrollButtons.right = outer.scrollLeft() > 0;
                this.scrollCompleted = false;
            }));
        },

        updateUI() {
            const outer = $(this.$refs.storiesOuterDiv);
            const maxWidth = outer.outerWidth(true);
            let actualWidth = 0;
            $.each(outer.children('#inner').children(), function(i, item) {
                actualWidth += $(item).outerWidth(true);
            });

            this.visibleScrollButtons.left = actualWidth > maxWidth;
            this.visibleScrollButtons.right = outer.scrollLeft() > 0;
            this.scrollCompleted =  outer.scrollLeft() + outer.get(0).clientWidth >= outer.get(0).scrollWidth;
        },

        goToDetail() {
            let url = new Url(this.posts[this.modalIndex].detail_href);
            if(this.currentPage !== null) {
                url.query.page = this.currentPage;
            }
            url.query.source = 'listing-stories';

            const pck = this.posts[this.modalIndex].pck;

            if (pck) url.query.pck = pck;

            this.$skokka.navigateTo(url);
        },

        openModal(postIndex) {
            if(this.modalIndex !== null) {
                return; // return because another modal is opening
            }

            this.modalIndex = postIndex;
            loadTouchSwipe().then(() => {
                return this.$nextTick();
            }).then(() => {
                // preload first image and then open the modal
                const picturesArray = this.posts[postIndex].pictures;
                const img = new Image();
                img.src = picturesArray[0];
                img.onload = (() => {
                    $(this.$refs.storiesModal).modal('show');
                    // preload the remaining images
                    picturesArray.slice(1).forEach(picture => {
                        (new Image()).src = picture;
                    });
                });

                // start animation when modal is shown
                $(this.$refs.storiesModal).on('shown.bs.modal', () => {
                    this.goToImage(0);
                });

                // reset status whn modal is closed
                $(this.$refs.storiesModal).on('hidden.bs.modal', () => {
                    this.modalIndex = null;
                    this.activeModalImageIndex = 0;
                    cancelAnimationFrame(this.currentAnimation.frameRequestId);
                    this.currentAnimation = getCurrentAnimationDefaults();
                });

                // event is fired when the carousel has completed its slide transition
                $(this.$refs.storiesModalCarousel).on('slid.bs.carousel', (evt) => {
                    this.goToImage(evt.to);
                });

                $(this.$refs.storiesModalInner).swipe({
                    swipeLeft: ((event, direction, distance, duration, fingerCount) => {
                        // console.log('swipeLeft', event);
                        $(this.$refs.storiesModalCarousel).carousel("next");
                    }),

                    swipeRight: ((event, direction, distance, duration, fingerCount) => {
                        // console.log('swipeRight', event);
                        $(this.$refs.storiesModalCarousel).carousel("prev");
                    }),

                    swipeStatus: ((event, phase, direction, distance , duration , fingerCount) => {
                        // console.log('swipeStatus', event, phase, direction, distance, $.fn.swipe.phases)
                        if(phase === $.fn.swipe.phases.PHASE_START || phase === $.fn.swipe.phases.PHASE_MOVE) {
                            this.currentAnimation.paused = true;
                        }else if(phase === $.fn.swipe.phases.PHASE_END || phase === $.fn.swipe.phases.PHASE_CANCEL) {
                            this.currentAnimation.paused = false;
                        }
                    }),
                    threshold: 30,
                    excludedElements: "label, button, input, select, textarea, .noSwipe",
                    triggerOnTouchLeave: true,
                });

            });
        },

        slideCarouselTo(index) {
            $(this.$refs.storiesModalCarousel).carousel(index);
        },

        goToImage(index) {
            this.activeModalImageIndex = index;

            // reset status
            cancelAnimationFrame(this.currentAnimation.frameRequestId);
            this.currentAnimation = getCurrentAnimationDefaults();

            // start timeline animation
            requestAnimationFrame(this.animateIndicator);
        },

        goToPreviousImage() {
            $(this.$refs.storiesModalCarousel).carousel('prev');
        },

        goToNextImage() {
            $(this.$refs.storiesModalCarousel).carousel('next');
        },

        animateIndicator(timestamp) {
            if(!this.currentAnimation.startTime) {
                this.currentAnimation.startTime = timestamp;
            }
            if(this.currentAnimation.paused && !this.currentAnimation.pauseStartTime) {
                // console.log('paused');
                this.currentAnimation.pauseStartTime = timestamp;
            }else if(!this.currentAnimation.paused && this.currentAnimation.pauseStartTime) {
                this.currentAnimation.totalPauseTime += timestamp - this.currentAnimation.pauseStartTime;
                // console.log('pause end', timestamp - this.currentAnimation.pauseStartTime);
                this.currentAnimation.pauseStartTime = null;
            }

            if(!this.currentAnimation.paused) {
                const runtime = timestamp - this.currentAnimation.startTime - this.currentAnimation.totalPauseTime;
                this.currentAnimation.timePerc = (runtime / this.storyTimeMs) * 100;
            }

            if (this.currentAnimation.timePerc < 100) {
                this.currentAnimation.frameRequestId = requestAnimationFrame(this.animateIndicator);
            } else {
                cancelAnimationFrame(this.currentAnimation.frameRequestId);
                this.currentAnimation.frameRequestId = -1;
                this.goToNextImage();
            }
        },

        thumbMediaSrc(post) {
            if(!this.imageSrcVersion.name || !this.imageSrcVersion.value) {
                return post.pictures[0];
            }
            const url = new Url(post.pictures[0]);
            // reset query parameters, to make sure that 'imageSrcVersion' is the last one in the url
            delete url.query["listing"];
            delete url.query[this.imageSrcVersion.name];
            url.query["listing"] = "supertop_stories_thumb";
            url.query[this.imageSrcVersion.name] = this.imageSrcVersion.value;
            return url.toString();
        },
    },
};
</script>
