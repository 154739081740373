<template>
    <div class="ui-message">
      <div class="ui-message__content">
        <div class="ui-message__title">
          <slot name="title"></slot>
        </div>
        <div class="ui-message__message">
          <slot name="message"></slot>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // import Translations from "./insert-me-to-translate.vue";
  
  export default {
    data() {
      return {};
    }
  };
  </script>
  
  <style>
  .ui-message {
    padding: 20px 8px;
    border-radius: 8px;
    background: var(--color-background-2);
    width: 100%;
    height: auto;
  }
  .ui-message__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    gap: 10px;
    text-align: center;
  }
  .ui-message__title {
    color: var(--color-alert);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ui-message__message {
    color: var(--color-message);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
  }
  </style>
  