export default {

    methods: {
        contactButtonAnalytics(isDetailView, postPublicId, contactType) {
            try {
                if(isDetailView) {
                    const analytics = document.querySelector("#post-analytics-data");
                    if(analytics) {
                        const eventData = JSON.parse(analytics.textContent);
                        eventData["event"] = "contact";
                        eventData["communication"] = contactType;
                        dataLayer.push(eventData);
                    }
                }else{
                    const analytics = document.querySelector("#data-layer-items");
                    if(analytics) {
                        const analyticsData = JSON.parse(analytics.textContent).find(e => e.event === 'view_post_list');
                        const postData = analyticsData.posts.find(p => p.post_id === postPublicId);
                        delete postData.position;
                        const eventData = {
                            event: 'contact',
                            communication: contactType,
                            city: analyticsData['city'],
                            posts: [postData],
                        };
                        if(analyticsData['region']) {
                            eventData['region'] = analyticsData['region'];
                        }
                        dataLayer.push(eventData);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
}
