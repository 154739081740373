var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isFree
    ? _c(
        "div",
        { staticClass: "tags-sections-detail" },
        [
          _vm._l(_vm.hierarchy.sections, function (section) {
            return !_vm.isPricingSection(section)
              ? _c("tags-list-detail", {
                  key: section.code,
                  attrs: { section: section },
                })
              : _vm._e()
          }),
          _vm._v(" "),
          _vm.showPricingSection
            ? _c("pricing-detail", {
                key: _vm.pricingSection.code,
                attrs: {
                  section: _vm.pricingSection,
                  "hourly-price": _vm.hourlyPrice,
                  currency: _vm.currency,
                },
              })
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }