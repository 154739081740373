var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shown
    ? _c(
        "div",
        { staticClass: "alert alert-secondary fixed-bottom text-center mb-0" },
        [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: {
                "aria-label": "Close",
                "data-dismiss": "alert",
                type: "button",
              },
              on: { click: _vm.close },
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          ),
          _vm._v(" "),
          _c("small", {
            domProps: { innerHTML: _vm._s(_vm.translations.alert_text) },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary mx-2",
              attrs: { type: "button" },
              on: { click: _vm.accept },
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.translations.i_accept) + "\n    "
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }