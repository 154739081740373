export const autorenewal = {
    namespaced: true,
    state: {
        promotion: null,
    },
    getters: {
        getPromotion: state => {
            if(state.promotion) {
                return state.promotion
            }
            return null;
        },
    },
    mutations: {
        SET_CURRENT_PROMOTION(state, promotion) {
            state.promotion = promotion;
        }
    },
    actions: {
        setCurrentPromotion({ commit }, promotion) {
            if (promotion) {
                commit('SET_CURRENT_PROMOTION', promotion);
            }
        }, 
        clearCurrentPromotion({ state, commit }) {
            if (state.promotion) {
                commit('SET_CURRENT_PROMOTION', null);
            }
        }
    }
}