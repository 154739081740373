import Vue from "vue";


export const order = {
    namespaced: true,
    state: {
        order: null,
        orderItems: [],
        paymentMethod: null,
        enoughCreditsStatus: null,
        enoughCreditsStatusChoices: {
            'NOT_ENOUGH_CREDITS': 0,
            'ALTERNATIVE_METHOD_SELECTED_AUTOMATICALLY': 1,
            'METHOD_SELECTED_BY_USER': 2
        },
        paymentMethodList: [],
        paymentSelectedByHuman: false,
        invoiceDetails: null,
        formErrors: {},

      // for terms and conditions
      terms: false,
      termsError: "",
    },
    mutations: {
        init(state, order) {
            state.order = order
        },
        addOrderItem(state, orderItem) {
            state.orderItems.push(orderItem)
        },
        removeOrderItem(state, orderItem) {
            state.orderItems.splice(state.orderItems.findIndex((element)=>{
                return element===orderItem;
            }))
        },
        setPaymentMethod(state, paymentMethod) {
            state.paymentMethod = paymentMethod
        },
        setEnoughCreditsStatus(state, status) {
            // check if status is a valid value
            let found = false;
            for (const prop in state.enoughCreditsStatusChoices) {
                if (state.enoughCreditsStatusChoices.hasOwnProperty(prop) && state.enoughCreditsStatusChoices[prop] === status) {
                  found = true;
                  break;
                }
              }
            if(!found) {
                throw new Error('Invalid status value: ' + id);
            }

            state.enoughCreditsStatus = status;
        },
        addPaymentMethod(state, paymentMethod){
            state.paymentMethodList.push(paymentMethod)
        },
        setPaymentSelectedByHuman(state) {
            state.paymentSelectedByHuman = true
        },
        setInvoiceDetails(state, invoiceDetails){
            state.invoiceDetails = invoiceDetails;
        },
        addFormError(state, error) {
            Object.keys(error).forEach(key => {
                Vue.set(state.formErrors, key, error[key]);
            });
        },
        removeFormError(state, key) {
            if(!key) {
                state.formErrors = {};
            } else {
                Vue.delete(state.formErrors, key);
            }
        },
    },
    actions: {
        init(context, order) {
            context.commit('init', order)
        },
        addOrderItem(context, orderItem) {
            context.commit('addOrderItem', orderItem)
        },
        addPremium(context,orderItem){
            context.commit('addPremium',orderItem)
        },
        removeOrderItem(context, orderItem){
            context.commit('removeOrderItem', orderItem)
        },
        setPaymentMethod(context, paymentMethod) {
            if (context.state.paymentMethod !== paymentMethod){
                // context.state.paymentMethodList.forEach(paymentMethod=>{
                //     console.log(paymentMethod.code)
                //     console.log(paymentMethod.$refs)
                //     console.log(paymentMethod.$refs.method)
                //     if (typeof(paymentMethod.$refs.method) !== "undefined") {
                //         if (typeof(paymentMethod.$refs.method.emptyFields) !== "undefined") {
                //             paymentMethod.$refs.method.emptyFields()
                //         }
                //     }
                // })
                context.commit('setPaymentMethod', paymentMethod)
                if(context.state.enoughCreditsStatus === context.state.enoughCreditsStatusChoices['NOT_ENOUGH_CREDITS']) {
                    // payment method selected because there are not enough credits available
                    context.commit('setEnoughCreditsStatus', context.state.enoughCreditsStatusChoices['ALTERNATIVE_METHOD_SELECTED_AUTOMATICALLY']);
                } else {
                    // payment method has been manually selected
                    context.commit('setEnoughCreditsStatus', context.state.enoughCreditsStatusChoices['METHOD_SELECTED_BY_USER']);
                }
            }
        },
        setEnoughCreditsStatus(context, value) {
            context.commit('setEnoughCreditsStatus', value);
        },
        addPaymentMethod(context, paymentMethod){
            context.commit('addPaymentMethod', paymentMethod)
        },
        setPaymentSelectedByHuman(context){
            context.commit('setPaymentSelectedByHuman')
        },
        setInvoiceDetails(context, invoiceDetails){
            context.commit('setInvoiceDetails', invoiceDetails);
        },
        addFormError(context, error){
            context.commit('addFormError', error);
        },
        removeFormError(context, key){
            context.commit('removeFormError', key);
        },
    },
    getters: {
        orderItems: state => {
            return state.orderItems
        },
        orderItemsLength: state => {
            return state.orderItems.reduce((accumulator, currOrderItem) => accumulator += (1 + currOrderItem.add_ons.length + currOrderItem.selected_addons.length), 0)
        },
        order: state => {
            return state.order
        },
        paymentMethod: state => {
            return state.paymentMethod
        },
        enoughCreditsStatusChoices: state => {
            return state.enoughCreditsStatusChoices;
        },
        enoughCreditsStatus: state => {
            return state.enoughCreditsStatus;
        },
        paymentMethodTypesShown: state => {
            let types = {}
            state.paymentMethodList.forEach((value, index)=>{
                types[value.type] = 0
            })
            return types
        },
        paymentMethods: state => {
            return state.paymentMethodList;
        },
        paymentSelectedByHuman: state => {
            return state.paymentSelectedByHuman
        },
        invoiceDetails: state => {
            return state.invoiceDetails;
        },
        formErrors: state => {
            return state.formErrors;
        },
    },
}
