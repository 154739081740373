import Vue from "vue";
import isString from 'lodash/isString';
import has from 'lodash/has';
import get from 'lodash/get';

export const recaptcha = {
    namespaced: true,
    state: {
        ready: false,
        response: null,
        recaptchaWithId: {},
        captchaComponent: null,
    },
    mutations: {
        setReady(state, value) {
            state.ready = value;
        },
        setResponse(state, response) {
            state.response = response;
        },
        setReadyWithId(state, { recaptchaId, value }) {
            if (!has(state.recaptchaWithId, recaptchaId)) {
                Vue.set(state.recaptchaWithId, recaptchaId, {});
            }
            Vue.set(state.recaptchaWithId[recaptchaId], 'ready', value);
        },
        setResponseWithId(state, { response, recaptchaId }) {
            if (!has(state.recaptchaWithId, recaptchaId)) {
                Vue.set(state.recaptchaWithId, recaptchaId, {});
            }
            Vue.set(state.recaptchaWithId[recaptchaId], 'response', response);
        },
        setCaptchaComponent(state, captchaComponent){
            state.captchaComponent = captchaComponent
        }
    },
    actions: {
        setReady({ commit, state }, { recaptchaId = null, value = true }) {
            if (isString(recaptchaId) && recaptchaId.length > 0) {
                if (get(state, `recaptchaWithId.${recaptchaId}.ready`) !== value) {
                    commit("setReadyWithId", { recaptchaId, value });
                }
            } else {
                if (state.ready !== value) {
                    commit("setReady", value);
                }
            }
        },
        setResponse({ commit, state }, { response, recaptchaId = null }) {
            if (isString(recaptchaId) && recaptchaId.length > 0) {
                if (get(state, `recaptchaWithId.${recaptchaId}.response`) !== response) {
                    commit("setResponseWithId", { response, recaptchaId });
                }
            } else {
                if (state.response !== response) {
                    commit("setResponse", response);
                }
            }
        },
        reset({ dispatch }, { recaptchaId = null }) {
            dispatch("setResponse", { recaptchaId, response: null });
            dispatch("setReady", { recaptchaId, value: false });
        },
        setCaptchaComponent({commit}, component) {
            console.log("committing captcha component")
            commit("setCaptchaComponent", component)
        }
    },
    getters: {
        response: state => {
            return state.response;
        },
        isReady: state => {
            return state.ready;
        },
        isFilled: state => {
            return !!state.response;
        },
        responseById: state => (recaptchaId) => {
            if (isString(recaptchaId) && recaptchaId.length > 0) {
                return get(state, `recaptchaWithId.${recaptchaId}.response`);
            }
            throw new Error(`recaptchaId is not a string: ${recaptchaId}`);
        },
        isReadyById: state => (recaptchaId) => {
            if (isString(recaptchaId) && recaptchaId.length > 0) {
                return get(state, `recaptchaWithId.${recaptchaId}.response`, false);
            }
            throw new Error(`recaptchaId is not a string: ${recaptchaId}`);
        },
        isFilledById: state => (recaptchaId) => {
            if (isString(recaptchaId) && recaptchaId.length > 0) {
                return !!get(state, `recaptchaWithId.${recaptchaId}.response`);
            }
            throw new Error(`recaptchaId is not a string: ${recaptchaId}`);
        },
        getCaptchaComponent: state => {
            return state.captchaComponent
        }
    }
}