export function copy(target, text) {
    const notificationText = gettext("Code copied");
    navigator.clipboard.writeText(text).then();
    const notificationTag = document.createElement('div');
    notificationTag.classList.add('copy-notification');
    notificationTag.innerText = notificationText;
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(notificationTag);
    notificationTag.style.display = 'block'
    setTimeout(function () {
        notificationTag.style.display = 'none'
        body.removeChild(notificationTag);
    }, 1000);
}