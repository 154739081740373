var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui-message" }, [
    _c("div", { staticClass: "ui-message__content" }, [
      _c("div", { staticClass: "ui-message__title" }, [_vm._t("title")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "ui-message__message" }, [_vm._t("message")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }