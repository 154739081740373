<script>
import PhoneButton from './phone-button.vue';
import WhatsappButton from './whatsapp-button.vue';

// Render html component for render html with vue components inside
export default {
  props: {
    html: {
      type: String,
      required: true,
    },
  },
  render(h) {
    const render = {
      components: {
        'phone-button': PhoneButton,
        'whatsapp-button': WhatsappButton,
      },
      template: "<div>" + this.html + "</div>",
    };
    return h(render);
  },
};
</script>
