var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.posts.length > 0
    ? _c("div", {}, [
        _c("div", { staticClass: "skokka-stories-list" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visibleScrollButtons.left,
                  expression: "visibleScrollButtons.left",
                },
              ],
              style: {
                visibility: _vm.visibleScrollButtons.right
                  ? "visible"
                  : "hidden",
              },
              attrs: { id: "stories-right-button" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.scrollRight.apply(null, arguments)
                },
              },
            },
            [_c("a", { attrs: { href: "#" } })]
          ),
          _vm._v(" "),
          _c("div", { ref: "storiesOuterDiv", attrs: { id: "outer" } }, [
            _c(
              "div",
              { attrs: { id: "inner" } },
              _vm._l(_vm.posts, function (post, index) {
                return _c(
                  "div",
                  { key: "thumb-" + index, staticClass: "stories_thumb" },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.openModal(index)
                          },
                        },
                      },
                      [
                        _c("picture", { staticClass: "stories_thumb-media" }, [
                          index === 0
                            ? _c("img", {
                                attrs: {
                                  src: _vm.thumbMediaSrc(post),
                                  alt: post.title,
                                  draggable: "false",
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: _vm.thumbMediaSrc(post),
                                  alt: post.title,
                                  draggable: "false",
                                  loading: "lazy",
                                },
                              }),
                        ]),
                        _vm._v(" "),
                        _vm._m(0, true),
                        _vm._v(" "),
                        _c("div", { staticClass: "user-username" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(post.title) +
                              "\n                        "
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              style: {
                visibility:
                  _vm.visibleScrollButtons.left && !_vm.scrollCompleted
                    ? "visible"
                    : "hidden",
              },
              attrs: { id: "stories-left-button" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.scrollLeft.apply(null, arguments)
                },
              },
            },
            [_c("a", { attrs: { href: "#" } })]
          ),
        ]),
        _vm._v(" "),
        _vm.modalIndex !== null
          ? _c(
              "div",
              {
                ref: "storiesModal",
                staticClass: "modalstories modal fade",
                attrs: {
                  interval: "0",
                  tabindex: "-1",
                  role: "dialog",
                  "aria-hidden": "true",
                  "data-backdrop": "dismiss",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-dialog modal-lg modal-dialog-centered",
                  },
                  [
                    _c("div", { staticClass: "modal-content bg-dark" }, [
                      _c("div", { staticClass: "modal-header border-0" }, [
                        _c(
                          "h5",
                          {
                            staticClass:
                              "modal-title text-title cursor-pointer onlydk",
                            on: { click: _vm.goToDetail },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.posts[_vm.modalIndex].title) +
                                "\n                        "
                            ),
                            _c("div", { staticClass: "tagcard" }, [
                              _c("span", { staticClass: "badge-pill" }, [
                                _c("b", [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.posts[_vm.modalIndex].age) +
                                      " " +
                                      _vm._s(_vm.i18nStrings.years) +
                                      "\n                                "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "badge-pill" }, [
                                _c("b", [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.posts[_vm.modalIndex].categoryName
                                      ) +
                                      "\n                                "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "badge-pill",
                                  attrs: { translate: "no" },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon icon-map-pin mr-1",
                                  }),
                                  _vm._v(" "),
                                  _c("b", [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.posts[_vm.modalIndex].postCityName
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]),
                                  _vm._v(
                                    _vm._s(
                                      _vm.posts[_vm.modalIndex].postListingPlace
                                        ? " / " +
                                            _vm.posts[_vm.modalIndex]
                                              .postListingPlace
                                        : ""
                                    ) + "\n                            "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(1),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-body" }, [
                        _c(
                          "div",
                          {
                            ref: "storiesModalCarousel",
                            staticClass: "carousel slide",
                            attrs: {
                              id: "storiesModalCarousel",
                              "data-interval": "0",
                            },
                          },
                          [
                            _vm.posts[_vm.modalIndex].pictures.length > 1
                              ? _c(
                                  "ol",
                                  {
                                    staticClass: "carousel-indicators stories",
                                  },
                                  _vm._l(
                                    _vm.posts[_vm.modalIndex].pictures,
                                    function (picture, index) {
                                      return _c("li", {
                                        key:
                                          "indicator-" +
                                          _vm.modalIndex +
                                          "-" +
                                          picture,
                                        class: {
                                          active:
                                            _vm.activeModalImageIndex === index,
                                        },
                                        style:
                                          "background-size: " +
                                          (_vm.activeModalImageIndex === index
                                            ? _vm.currentAnimation.timePerc
                                            : 0) +
                                          "% 100%",
                                        on: {
                                          click: function ($event) {
                                            return _vm.slideCarouselTo(index)
                                          },
                                        },
                                      })
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                ref: "storiesModalInner",
                                staticClass: "carousel-inner modalstories",
                                attrs: { role: "listbox" },
                              },
                              _vm._l(
                                _vm.posts[_vm.modalIndex].pictures,
                                function (picture, index) {
                                  return _c("div", {
                                    key:
                                      "img-" + _vm.modalIndex + "-" + picture,
                                    ref: "carouselPicture" + index,
                                    refInFor: true,
                                    class:
                                      "carousel-item " +
                                      (_vm.activeModalImageIndex === index
                                        ? "active"
                                        : "modalstories"),
                                    style:
                                      "background-image: url('" +
                                      picture +
                                      "')",
                                  })
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _vm.posts[_vm.modalIndex].pictures.length > 1
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "carousel-control-prev",
                                    attrs: { role: "button" },
                                    on: { click: _vm.goToPreviousImage },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "carousel-control-prev-icon",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v(_vm._s(_vm.i18nStrings.previous)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.posts[_vm.modalIndex].pictures.length > 1
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "carousel-control-next",
                                    attrs: { role: "button" },
                                    on: { click: _vm.goToNextImage },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "carousel-control-next-icon",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v(_vm._s(_vm.i18nStrings.next)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "modal-footer justify-content-center border-0 w-100",
                        },
                        [
                          _c(
                            "h5",
                            {
                              staticClass:
                                "modal-title text-title cursor-pointer onlymobile w-100",
                              on: { click: _vm.goToDetail },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.posts[_vm.modalIndex].title) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "tagcard onlymobile" }, [
                            _c("span", { staticClass: "badge-pill" }, [
                              _c("b", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.posts[_vm.modalIndex].age) +
                                    " " +
                                    _vm._s(_vm.i18nStrings.years) +
                                    "\n                            "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "badge-pill" }, [
                              _c("b", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.posts[_vm.modalIndex].categoryName
                                    ) +
                                    "\n                            "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "badge-pill",
                                attrs: { translate: "no" },
                              },
                              [
                                _c("i", {
                                  staticClass: "icon icon-map-pin mr-1",
                                }),
                                _vm._v(" "),
                                _c("b", [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.posts[_vm.modalIndex].postCityName
                                      ) +
                                      "\n                                "
                                  ),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.posts[_vm.modalIndex].postListingPlace
                                      ? " / " +
                                          _vm.posts[_vm.modalIndex]
                                            .postListingPlace
                                      : ""
                                  ) + "\n                        "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                              on: { click: _vm.goToDetail },
                            },
                            [_vm._v(_vm._s(_vm.i18nStrings.goToDetailText))]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "badge badge-supertop mt-3" }, [
      _c("i", { staticClass: "icon-supertop mr-1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: { type: "button", "data-dismiss": "modal" },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }