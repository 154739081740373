var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container listing sticky-top bg-white p-2" },
    [
      _c("section", { staticClass: "listing-tags" }, [
        _c("div", { staticClass: "stikysearch" }, [
          _c(
            "a",
            {
              attrs: {
                "data-toggle": "modal",
                "data-target": "#staticBackdrop",
              },
              on: {
                click: function ($event) {
                  return _vm.activateSearchDialog()
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center py-2 px-3",
                },
                [
                  _c("span", { class: { nogeo: !_vm.locationText } }, [
                    _c("span", { staticClass: "icon-map-pin mr-2" }),
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.locationText
                            ? _vm.locationText
                            : _vm.i18nStrings.placeholder
                        ) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "i",
                    { staticClass: "text-clipped d-flex justify-content-end" },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            fill: "none",
                            viewBox: "0 0 24 24",
                            "stroke-width": "3",
                            stroke: "currentColor",
                            width: "18",
                            height: "18",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              d: "M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row inlinetag px-2 pt-2 pb-0 " }, [
          _c(
            "div",
            { staticClass: "taglist filtersearch" },
            _vm._l(_vm.tags, function (tag) {
              return _c(
                "label",
                {
                  key: tag.code,
                  on: {
                    click: function ($event) {
                      return _vm.removeTag(tag)
                    },
                  },
                },
                [
                  _c("input", {
                    staticClass: "Checkbox",
                    attrs: { type: "checkbox" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "listingfilters" }, [
                    _c("span", {
                      staticClass: "listingfiltersClose icon icon-close",
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(tag.title))]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }