
<script>
export default {
    name: 'SeoHeader',
    data(){
        return {user_action:false}
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods:{
        userAction(){this.user_action=true;},
        handleScroll(){if(!this.user_action) $('#collapseHeaderText').collapse('hide')}
    }
};
</script>
