<script>
    // import Translations from "./insert-me-to-translate.vue";

    import { getLightboxName } from '../utils';
    
    const cookieName = 'show_block_lightbox';

    export default {
        props: [
            'initPostUpdateHref',
            'initIsUpdatePage',
            'initTcUrl',
            'initSupportEmail'
        ],
        data(){
            return  {
                lightboxName: null,
                hasCookie: !!this.$cookies.get(cookieName) || false,
                postUpdateHref: this.initPostUpdateHref,
                isUpdatePage: this.initIsUpdatePage,
                translations: {
                    warning: gettext("WARNING"),
                    similar_ad: gettext("Recently it has been published a similar ad. Edit your ad to publish it."),
                    edit: gettext("Edit"),
                    modal_body: String(gettext('This ad was prevented from insertion due to violations of <a data-href="%(tc_url)s">Terms and conditions</a>.<br>For more information send an email to: ')).replace('%(tc_url)s', this.initTcUrl) + this.initSupportEmail,
                    close: gettext("Close")
                }
            }
        },
        computed:{
            shown() {
                return this.$store.getters['ui/lightboxManager/lightboxIsShown'](this.lightboxName);
            }
        },
        mounted() {
            this.lightboxName = getLightboxName({ el: this.$el })

            if (!this.hasCookie) {
                this.$store.dispatch("ui/lightboxManager/removeAndHide", this.lightboxName);
            }  
        },
        watch: {
            shown(isShown) {
                if(isShown) {
                    this.removeCookie();
                }
            }
        },
        methods: {
            removeCookie() {
                if(this.hasCookie) {
                    this.$cookies.remove(cookieName, null, locations.cookie_domain);
                }
            },
            onClose() {
                this.removeCookie();
                this.$store.dispatch("ui/lightboxManager/hide");
            },
            onEdit() {
                if (this.isUpdatePage) {
                    this.onClose();
                } else {
                    if (this.postUpdateHref) {
                        window.location.href = this.postUpdateHref;
                    } else {
                        this.onClose();
                    }
                }
            }
        }
    };
</script>

<template>
    <div id="lightbox-block" class="modal" :class="{ show: shown }"  tabindex="-1" data-backdrop="static" ref="lightbox" :style="shown ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <p class="modal-title text-uppercase" v-html="this.translations.warning"></p>
                </div>
                <div v-if="postUpdateHref || isUpdatePage">
                    <div class="modal-body">
                        <div class="d-flex flex-column bd-highlight mb-3">
                            <div class="p-2 bd-highlight">
                                <div v-html="this.translations.similar_ad"></div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <div>
                            <button type="button" @click="onEdit" class="btn btn-primary waves-effect waves-light" v-html="this.translations.edit"></button>
                        </div>
                    </div>
                </div>
                <!-- v-else is for the homepage -->
                <div v-else>
                    <div class="modal-body">
                        <div class="d-flex flex-column bd-highlight mb-3">
                            <div class="p-2 bd-highlight" v-html="this.translations.modal_body"></div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" @click="onClose" class="btn btn-primary waves-effect waves-light" v-html="this.translations.close"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
