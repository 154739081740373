<script>

export default {
    name: "CarouselText",
    props: {
        strings: {
            type: Array,
            required: true
        },
        id: {
            type: String,
            required: false
        }
    },
    computed: {
        carouselId(){
            if (this.$props.id) return this.$props.id;
            const randomHexValue = (Math.random() + 1).toString(16).substring(2);
            return `carousel-${randomHexValue}`;            
        }
    }
}

</script>

<template>
    <div v-bind:id="this.carouselId" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">

            <div v-for="(string, index) in strings" :key="index" class="carousel-item " :class="{ 'active' : index === 0 }">
                {{ string }}
            </div>           
            <a class="carousel-control-prev" v-bind:data-href="this.carouselId" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" v-bind:data-href="this.carouselId" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</template>