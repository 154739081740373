import {lightbox} from "./modules/lightbox";
import {loader} from "./modules/loader";
import {recaptcha} from "./modules/recaptcha";
import {searchModal} from "./modules/search-modal";
import {lightboxManager} from "./modules/lightboxManager";

export const ui = {
    namespaced: true,
    modules: {
        lightbox,
        loader,
        recaptcha,
        searchModal,
        lightboxManager
    }
}

