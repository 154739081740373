import Vue from "vue";

Vue.directive('trans', {
   bind (el, binding, _vnode) {
       el.innerHTML=gettext(binding.value);
       el.removeAttribute("data-trans");
   }
})

Vue.directive('on-escape', {
    bind (el, binding, vnode) {
        el.customEventKeydown = function (event) {
            if (event?.keyCode === 27) {
                vnode?.context[binding.expression](event)
            }
        }
        document.body.addEventListener("keydown", el.customEventKeydown)
    },
    unbind (el) {
        document.body.removeEventListener("keydown", el.customEventKeydown)
    }
})