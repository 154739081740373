<script>
    import Url from "domurl";

    export default {
        props: ['initPage', 'initHasNext', 'initHasPrevious', 'initBaseUrl'],

        data: function(){
            return {
                page: parseInt(this.initPage),
                hasNext: this.initHasNext === 'true',
                hasPrevious: this.initHasPrevious === 'true',
                baseUrl: this.initBaseUrl,
                query: {
                    "q": "",
                    "type": "",
                    "city":"",
                    "category":"",
                    "email":"",
                    "promoteFrom":"",
                    "promoteTo":"",
                    "expiringAd":"",
                    "expiredPromo":"",
                    "status": "",
                    "tags": "",
                }
            }
        },

        methods: {
            change: function (page, event) {
                let url = new Url(this.baseUrl);
                if(locations.language_prefix && url.path.lastIndexOf(locations.language_prefix+'/', 0) !== 0){
                    url.path = locations.language_prefix + url.path;
                }
                for(let key in this.query){
                    this.query[key]=url.query[key]
                }
                url.clearQuery();
                for(let key in this.query){
                    if(this.query[key]){
                        url.query[key]=this.query[key]
                    }
                }
                if(page > 1) {
                    url.query.p = page;
                }
                window.location = url;
            }
        }
    };

</script>
