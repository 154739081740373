<template>
  <div class="pricing-detail">
    <hr class="my-4">
    <h5>
      <strong>
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="26" height="26">
            <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"></path>
          </svg>
        </i>
        {{ i18nStrings.title }}
      </strong>
    </h5>
    <small v-if="hourlyPrice"><b>{{ i18nStrings.subtitle }}</b></small>
    <ul class="list-group price-hour" v-if="hourlyPrice">
      <li class="list-group-item d-flex justify-content-between align-items-center">
        {{ i18nStrings.from }}
        <span class="badge badge-light badge-pill">{{ currency }} {{ hourlyPrice }}</span>
      </li>
    </ul>
    <p class="mt-2">
      <span class="badge badge-pill" v-for="tag in section.tags" :key="tag.id">
        <svg v-if="tag.code === 'credit_card'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="14" height="14">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"></path>
        </svg>
        <svg v-else-if="tag.code === 'cash'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="14" height="14">
          <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"></path>\n' +
        </svg>
        <i v-else-if="tag.code === 'pix'" class="icon-pix mr-2"></i>
        {{ tag.title }}
      </span>
    </p>
  </div>
</template>

<script>
// import Translations from "./insert-me-to-translate.vue";

export default {
  name: 'PricingDetail',
  props: {
    section: Object,
    hourlyPrice: Number,
    currency: String
  },
  data() {
    return {
      i18nStrings: {
        title: gettext("Payments"),
        subtitle: gettext("Price per hour"),
        from: gettext("From"),
      },
    }
  },
}
</script>
