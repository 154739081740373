<template>
  <div v-if="templateHtml" class="container px-0 related">
    <div class="col mt-5 mb-5">
      <RenderHtml :html="templateHtml"></RenderHtml>
    </div>
  </div>
</template>

<script>
import RenderHtml from "../render-html.vue";
export default {
  components: {
    RenderHtml,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      templateHtml: null,
      error: null,
    };
  },
  mounted: function () {
    this.$http.get(this.url).then((response) => {
      if (response.data.trim() !== "") {
        this.templateHtml = response.data;
        this.initializeCarousel();
      } else {
        this.error = "No data found";
      }
    });
  },

  methods: {
    // Start the bootstrap carousel if exists
    initializeCarousel: function () {
      this.$nextTick(() => {
        $(".related .carousel").carousel();
      });
    },
  },
};
</script>
