import isSafeInteger from 'lodash/isSafeInteger';
import transform from 'lodash/transform';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import forOwn from 'lodash/forOwn';
import {copy} from '../services/copy_to_clipboard';
import { canUseCookies } from '../utils';


const skokka = (function() {
    let lastListingUrl = null;
    let lastListingUrlCookieName = 'last_listing_url';

    function goToLastListing(fallback = "/") {
        const lastListingUrl = window.$cookies.get(lastListingUrlCookieName);
        const urlToNavigate = lastListingUrl || fallback;
        navigateTo(urlToNavigate);
    }

    function setLastListingUrl(url) {
        if (canUseCookies) {
            //TODO: remove this when we change the domain structur in italy should be replaced by session storage
            if(locations.isBakecaincontri()){
                window.$cookies.set(lastListingUrlCookieName, url,'1d', '/', locations.cookie_domain);
            }
            else{
                window.$cookies.set(lastListingUrlCookieName, url);
            }
            lastListingUrl = url;
        }
    }
    function deleteFunctionalCookies() {
        let functional_cookies=locations.functional_cookies;
        for(let i=0;i<functional_cookies.length;i++){
            window.$cookies.remove(functional_cookies[i], null, locations.cookie_domain);
            if(functional_cookies[i]==='last_listing_url'){
                window.$cookies.remove(functional_cookies[i]);
            }

        }
    }

    function deletePerformanceCookies() {
        let performance_cookies = locations.performance_cookies;
        for(let i=0; i<performance_cookies.length; i++){
            window.$cookies.remove(performance_cookies[i], null, locations.cookie_domain);
        }
    }

    function deleteAdvertisingCookies() {
        let advertising_cookies = locations.advertising_cookies;
        for(let i=0; i<advertising_cookies.length; i++){
            window.$cookies.remove(advertising_cookies[i], null, locations.cookie_domain);
        }
    }

    function getLastListingUrl(){
        return lastListingUrl;
    }

    function scrollToElement(el, params, forced){

      if (!params) params = {};
      if (! typeof(forced) === 'boolean') forced = false;

      if(!isElementInViewport(el) || forced){
          el.scrollIntoView(params);
      }
    }

    function isElementInViewport(el) {
        let rect = el.getBoundingClientRect();
        return rect.bottom > 0 &&
            rect.right > 0 &&
            rect.left >= 0 &&
            rect.top >= 0 &&
            rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
            rect.top < (window.innerHeight || document.documentElement.clientHeight);
    }

    function scrollToError(){
        let el = document.querySelector('.error-anchor');
        if(el){
            scrollToElement(el);
        }
    }

    function navigateTo(url, _blank){
        if(_blank){
            let win = window.open(url, '_blank');
            win.focus();
        }else{
            window.location = url;
        }
    }

    function formatNumber(number, locale, decimalPrecision = 0, isCurrency = false) {
        const negative = number < 0 ? true : false;
        const absNumber = Math.abs(number);
        const absInt = decimalPrecision > 0 ? Math.floor(absNumber) : Math.round(absNumber);

        const groupingSet = isCurrency ? 'currency_grouping' : 'decimal_grouping';

        const localeFormat = locations.getFormats()[locale];

        const firstGroupSize = localeFormat[groupingSet][0];
        const isInFirstGroupRange = absInt < Math.pow(10, firstGroupSize);
        if (!isCurrency && isInFirstGroupRange) {
            // no formatting needed
            return number.toString();
        }

        let resString = '';

        if (isInFirstGroupRange) {
            resString = absInt.toString();
        } else {
            // add grouping separators
            let currentGroup = 0
            let currentGroupSize = localeFormat[groupingSet][currentGroup];

            const absIntArray = absInt.toString().split('');

            let digit = absIntArray.pop();
            let loopGroupIndex = 0;
            while (digit) {
                if (loopGroupIndex < currentGroupSize) {
                    resString = digit + resString;
                    loopGroupIndex++;
                } else {
                    // add separator and move to next group
                    resString = digit + localeFormat['group_separator'] + resString;
                    loopGroupIndex = 1;
                    currentGroup ++;
                    if (localeFormat[groupingSet].length > currentGroup) {
                        // update group size if available, otherwise use the last value in array
                        currentGroupSize = localeFormat[groupingSet][currentGroup];
                    }
                }
                digit = absIntArray.pop();
            }
        }

        // decimal part
        if(decimalPrecision > 0) {
            resString = resString + (isCurrency ? localeFormat['currency_decimal_separator'] : localeFormat['decimal_separator']);
            resString = resString + (number % 1).toFixed(decimalPrecision).split('.')[1];
        }

        // replace spaces with nbsp
        resString = resString.replace(/\s/g, '\xa0');

        // add minus sign
        return negative ? '-' + resString : resString;
    }

    function formatCurrency(amount, locale, currencyCode) {
        // this function doesn't add currency symbol or name to formatted value

        const localeFormat = locations.getFormats()[locale];

        let precision = 0;

        if (currencyCode === localeFormat['currency_code']) {
            precision = localeFormat['currency_precision'];
        } else {
            const options = new Intl.NumberFormat(locale, { style: 'currency', currency: currencyCode }).resolvedOptions();
            precision = options.minimumFractionDigits;
        }

        return formatNumber(amount, locale, precision, true);
    }

    function formatInteger(number, locale) {
        if(!isSafeInteger(number)) {
            throw new Error('Not an integer number: ' + number);
        }
        return formatNumber(number, locale);
    }

    /**
    * Deep diff between two objects - i.e. an object with the new value of new & changed fields.
    * Removed fields will be set as undefined on the result.
    * Only plain objects will be deeply compared (@see _.isPlainObject)
    *
    * Inspired by: https://gist.github.com/Yimiprod/7ee176597fef230d1451#gistcomment-2565071
    * This fork: https://gist.github.com/TeNNoX/5125ab5770ba287012316dd62231b764/
    *
    * @param  {Object} base   Object to compare with (if falsy we return object)
    * @param  {Object} object Object compared
    * @return {Object}        Return a new object who represent the changed & new values
    */
     function deepDiffObj(base, object) {
        if (!object)
            throw new Error(`The object compared should be an object: ${object}`);
        if (!base) return object;
        const result = transform(object, (result, value, key) => {
            if (!has(base, key)) result[key] = value; // fix edge case: not defined to explicitly defined as undefined
            if (!isEqual(value, base[key])) {
                result[key] =
                    isPlainObject(value) && isPlainObject(base[key])
                        ? deepDiffObj(base[key], value)
                        : value;
            }
        });
        // map removed fields to undefined
        forOwn(base, (value, key) => {
            if (!has(object, key)) result[key] = undefined;
        });
        return result;
    }

    function timeoutPromise(timeoutMs) {
        return new Promise(resolve => setTimeout(resolve, timeoutMs));
    }

    return {
        goToLastListing,
        setLastListingUrl,
        getLastListingUrl,
        deleteFunctionalCookies,
        deletePerformanceCookies,
        deleteAdvertisingCookies,
        scrollToElement,
        scrollToError,
        navigateTo,
        formatCurrency,
        formatInteger,
        deepDiffObj,
        copy,
        timeoutPromise,
    }
})();

export default skokka;
